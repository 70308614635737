import React from "react";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import arrowRight from "../../assets/icons/arrow_right_alt.svg";

const Final = ({ giftLink, registerLink }) => {
  return (
    <div className={style.final}>
      <div className={`wrapper ${style.final__wrapper}`}>
        <Header registerLink={registerLink} giftLink={giftLink} />

        <div className={style.final__container}>
          <h1>Игра пройдена!</h1>
        </div>
      </div>
    </div>
  );
};

export default Final;
